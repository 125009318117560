//configuracion de peticiones ajax
//esta configuracion sera ejecutada en todas las peticiones ajax que se realicen en el panel
$.ajaxSetup({
    dataType: "json",
    error: function (jqXHR, textStatus, errorMsg) {
        //informacion del error
        let status_code = jqXHR.status
        let response = jqXHR.responseJSON

        //si el error es por autorizacion
        if (status_code === 401) {
            //si el token es renovado
            if (response.message === 'TOKEN_EXPIRED_AND_REFRESHED') {
                $.ajax({
                    method: "GET",
                    url: base_url + "token/login",
                    dataType: "json",
                    headers: {
                        Authorization: "Bearer " + response.data.token
                    },
                }).done(function (data) {
                    if (data.status === 'success') {
                        window.location.href = base_url + 'panel'
                    } else {
                        //cerrar la sesion del usuario
                        force_logout()
                    }
                })
            } else {
                //cerrar la sesion del usuario
                force_logout()
            }
        } else {
            //mostrar mensaje de error y mantener el usuario en sesion
            basic_dialog('Error', 'Ha ocurrido un error, intente de nuevo más tarde', 'error')
        }
    },

});

/**
 * Permite forzar el logout del usuario
 */
function force_logout() {
    $.ajax({
        method: "GET",
        url: base_url + "token/logout",
        dataType: "json",
        headers: {
            Authorization: "Bearer " + localStorage.getItem('access_token')
        },
    }).always(function () {
        localStorage.clear()
        window.location.href = base_url + 'login'
    });
}
